import React from 'react'
import * as styles from "./term.module.scss"
import { CalculatorLink } from "../../../components/calculator/calculatorLink"


export const ServicesTermArchitektura = () => {
  return (
    <div>
      <h2 className={styles.services_term_title}>Сроки и стоимость</h2>
      <div className={styles.services_term}>
        <div className={styles.services_term_wrap1}>
          <h3 className={styles.services_term_item_title}>Проектирование</h3>
          <div className={styles.services_term_item1}>
            <p className={styles.services_term_item_p1}>Изучение исходных данных, подготовка задания на проектирование и разработка нескольких вариантов планировочных концепций:</p>
            <p className={styles.services_term_item_p2}>30 рабочих дней</p>
          </div>
          <div className={styles.services_term_item2}>
            <p className={styles.services_term_item_p1}>Разработка полного комплекта проектной документации и 3D-визуализации:</p>
            <p className={styles.services_term_item_p2}>90 рабочих дней*</p>
            <p className={styles.services_term_item_span}>*с момента утверждения планировочной концепции и задания на проектирование.</p>
          </div>
        </div>
        <div className={styles.services_term_item3 + " " + styles.services_term_item4}>
          <h3 className={styles.services_term_item_title}>Строительные работы, архитектура</h3>
          <p className={styles.services_term_item_p2}>12-18 месяцев</p>
          <p className={styles.services_term_item_p1}>Сопровождаем строительство и комплектуем объект всеми необходимыми материалами и оборудованием.</p>
        </div>
        <div className={styles.services_term_item3 + " " + styles.services_term_item4}>
          <h3 className={styles.services_term_item_title}>Строительные работы, интерьер</h3>
          <p className={styles.services_term_item_p2}>12-18 месяцев</p>
          <p className={styles.services_term_item_p1}>Черновые работы, чистовые работы, финишные работы.</p>
        </div>
        <div className={styles.services_term_item3}>
          <h3 className={styles.services_term_item_title}>Комплектация</h3>
          <p className={styles.services_term_item_p2}>6-8 месяцев</p>
          <p className={styles.services_term_item_p1}>Организуем процесс по поиску и закупке необходимых материалов и мебели. Работаем только с надежными поставщиками.</p>
        </div>
      </div>
      <CalculatorLink />
    </div>
  )
}